<template>
	<div>
		<el-dialog
			:title="titleDialog"
			:visible="showDialog"
			@close="close"
			@open="create"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			append-to-body
			width="65%"
		>
			<form autocomplete="off" @submit.prevent="submit">
				<v-row no-gutters>
					<v-col cols="12" xl="6" lg="6" md="6" sm="6">
						<el-button
							:type="form.transformed ? 'primary' : 'default'"
							class="btn-block"
							@click="changeTranformed(true)"
						>
							TRANSFORMADO
						</el-button>
					</v-col>
					<v-col cols="12" xl="6" lg="6" md="6" sm="6">
						<el-button
							:type="!form.transformed ? 'primary' : 'default'"
							class="btn-block"
							@click="changeTranformed(false)"
						>
							NO TRANSFORMADO
						</el-button>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" xl="3" lg="3" md="3" sm="6">
						<div class="form-group" :class="{ 'error--text': errors.category_id }">
							<label class="control-label"> Categoría</label>
							<el-select v-model="form.category_id" filterable>
								<el-option
									v-for="option in categories"
									:key="option.id"
									:value="option.id"
									:label="option.name"
								></el-option>
							</el-select>
							<a
								href="#"
								@click.prevent="showDialogNewCategory = true"
								class="primary"
								>[+ Nuevo]</a
							>
							<small
								class="error--text"
								v-if="errors.category_id"
								v-text="errors.category_id[0]"
							></small>
						</div>
					</v-col>
					<v-col cols="12" xl="3" lg="3" md="3" sm="6">
						<div class="form-group" :class="{ 'error--text': errors.command_zone_id }">
							<label class="control-label">Zona de producción</label>
							<el-select v-model="form.command_zone_id">
								<el-option
									v-for="option in commandZones"
									:key="option.id"
									:value="option.id"
									:label="option.name"
								></el-option>
							</el-select>
							<small
								class="error--text"
								v-if="errors.command_zone_id"
								v-text="errors.command_zone_id[0]"
							></small>
						</div>
					</v-col>
					<v-col cols="12" xl="6" lg="6" md="6" sm="12">
						<div class="form-group" :class="{ 'error--text': errors.name }">
							<label class="control-label">Nombre</label>
							<el-input v-model="form.name" dusk="name"></el-input>
							<small
								class="error--text"
								v-if="errors.name"
								v-text="errors.name[0]"
							></small>
						</div>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" xl="12" lg="12" md="12" sm="12">
						<div class="form-group" :class="{ 'error--text': errors.description }">
							<label class="control-label">Descripción</label>
							<el-input v-model="form.description" dusk="description"></el-input>
							<small
								class="error--text"
								v-if="errors.description"
								v-text="errors.description[0]"
							></small>
						</div>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" xl="4" lg="4" md="4" sm="6">
						<div class="form-group" :class="{ 'error--text': errors.internal_id }">
							<label class="control-label"
								>Código Interno
								<el-tooltip
									class="item"
									effect="dark"
									content="Código interno de la empresa para el control de sus productos"
									placement="top-start"
								>
									<i class="fa fa-info-circle"></i>
								</el-tooltip>
							</label>
							<el-input v-model="form.internal_id" dusk="internal_id"></el-input>
							<small
								class="error--text"
								v-if="errors.internal_id"
								v-text="errors.internal_id[0]"
							></small>
						</div>
					</v-col>
					<v-col cols="12" xl="4" lg="4" md="4" sm="6">
						<div class="form-group" :class="{ 'error--text': errors.item_code }">
							<label class="control-label"
								>Código Sunat
								<el-tooltip
									class="item"
									effect="dark"
									content="Código proporcionado por SUNAT, campo obligatorio para exportaciones"
									placement="top-start"
								>
									<i class="fa fa-info-circle"></i>
								</el-tooltip>
							</label>
							<el-input v-model="form.item_code" dusk="item_code"></el-input>
							<small
								class="error--text"
								v-if="errors.item_code"
								v-text="errors.item_code[0]"
							></small>
						</div>
					</v-col>
					<v-col cols="12" xl="4" lg="4" md="4" sm="12">
						<div class="form-group" :class="{ 'error--text': errors.unit_type_id }">
							<label class="control-label">Unidad</label>
							<el-select v-model="form.unit_type_id" dusk="unit_type_id" filterable>
								<el-option
									v-for="option in unitTypes"
									:key="option.id"
									:value="option.id"
									:label="option.description"
								></el-option>
							</el-select>
							<small
								class="error--text"
								v-if="errors.unit_type_id"
								v-text="errors.unit_type_id[0]"
							></small>
						</div>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" xl="4" lg="4" md="4" sm="12" v-if="form.presentation">
						<div class="form-group">
							<label class="control-label">Precio de venta en local</label>
							<el-input
								v-model="form.presentation.sale_price"
								dusk="sale_price"
							></el-input>
						</div>
					</v-col>
					<v-col cols="12" xl="4" lg="4" md="4" sm="12" v-if="form.delivery">
						<div class="form-group" :class="{ 'error--text': errors.delivery_price }">
							<label class="control-label">Precio Delivery</label>
							<el-input
								v-model="form.delivery_price"
								dusk="delivery_price"
							></el-input>
							<small
								class="error--text"
								v-if="errors.delivery_price"
								v-text="errors.delivery_price[0]"
							></small>
						</div>
					</v-col>
					<v-col cols="12" xl="4" lg="4" md="4" sm="12">
						<div class="form-group" :class="{ 'error--text': errors.promotion }">
							<label class="control-label">Producto en promoción</label>
							<el-select v-model="form.promotion" dusk="promotion" @change="changePromo()">
								<el-option :key="1" :value="true" label="Si"></el-option>
								<el-option :key="2" :value="false" label="No"></el-option>
							</el-select>
							<!-- <el-switch v-model="form.promotion" active-text="Si" inactive-text="No"></el-switch> -->
							<small
								class="error--text"
								v-if="errors.promotion"
								v-text="errors.promotion[0]"
							></small>
						</div>
					</v-col>
					<v-col cols="12" xl="4" lg="4" md="4" sm="12" v-if="form.promotion">
						<div
							class="form-group"
							:class="{ 'error--text': errors.date_of_due_promo }"
						>
							<label class="control-label">Promoción válida hasta:</label>
							<el-date-picker
								v-model="form.date_of_due_promo"
								type="date"
								value-format="yyyy-MM-dd"
								format="dd/MM/yyyy"
								:picker-options="pickerOptions"
								:clearable="false"
							></el-date-picker>
							<small
								class="error--text"
								v-if="errors.date_of_due_promo"
								v-text="errors.date_of_due_promo[0]"
							></small>
						</div>
					</v-col>
					<v-col cols="12" xl="4" lg="4" md="4" sm="12" v-if="form.stock_control && !form.id">
						<div class="form-group" :class="{ 'error--text': errors.stock }">
							<label class="control-label">Stock inicial</label>
							<el-input v-model="form.stock" dusk="stock"></el-input>
							<small
								class="error--text"
								v-if="errors.stock"
								v-text="errors.stock[0]"
							></small>
						</div>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" xl="3" lg="3" md="3" sm="12">
						<el-checkbox v-model="form.favorite">Favorito</el-checkbox>
					</v-col>
					<v-col cols="12" xl="3" lg="3" md="3" sm="12">
						<el-checkbox v-model="form.has_igv">Incluye Igv</el-checkbox>
					</v-col>
					<v-col cols="12" xl="3" lg="3" md="3" sm="12">
						<el-checkbox v-model="form.delivery">Delivery</el-checkbox>
					</v-col>
					<v-col cols="12" xl="3" lg="3" md="3" sm="12" v-if="!form.transformed">
						<el-checkbox v-model="form.stock_control" :disabled="disabledChecks">Control de stock</el-checkbox>
					</v-col>
					<v-col cols="12" xl="3" lg="3" md="3" sm="12" v-else>
						<el-checkbox v-model="form.has_recipe" :disabled="disabledChecks">Tiene receta</el-checkbox>
					</v-col>
				</v-row>
				<v-row dense v-if="form.transformed && !form.promotion">
					<el-divider>REGISTRO DE INSUMOS</el-divider>
					<v-col cols="12" xl="12" lg="12">
						<v-simple-table>
							<template v-slot:default>
								<thead>
									<tr>
										<th class="text-left">
											Insumo
										</th>
										<th class="text-right">
											Cantidad
										</th>
										<th class="text-right">
											Unidad M.
										</th>
										<th class="text-right">
											Acciones
										</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(row, index) in form.ingredients" :key="index">
										<td>
											<el-select size="mini" v-model="row.ingredient_id" filterable>
												<el-option
													v-for="option in ingredients"
													:key="option.id"
													:value="option.id"
													:label="option.name"
												></el-option>
											</el-select>
										</td>
										<td>
											<el-input-number
												size="mini"
												v-model="row.quantity"
												controls-position="right"
												:min="0.125"
												:step="0.1"
											></el-input-number>
										</td>
										<td>
											<el-select size="mini" v-model="row.unit_type_id" filterable>
												<el-option
													v-for="option in unitTypes"
													:key="option.id"
													:value="option.id"
													:label="option.description"
												></el-option>
											</el-select>
										</td>
										<td>
											<el-button
												size="mini"
												type="danger"
												icon="el-icon-delete"
												@click="removeIngredient(index)"
											>
											</el-button>
										</td>
									</tr>
									<tr>
										<td class="text-right">
											<el-button
												size="mini"
												type="info"
												icon="el-icon-plus"
												@click="addIngredient"
											>
											</el-button>
										</td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-col>
					<el-divider></el-divider>
				</v-row>
				<v-row dense v-if="form.promotion">
					<el-divider>PRODUCTOS QUE INCLUYE LA PROMOCIÓN</el-divider>
					<v-col cols="12" xl="12" lg="12">
						<v-simple-table>
							<template v-slot:default>
								<thead>
									<tr>
										<th class="text-left">
											Producto
										</th>
										<th class="text-right">
											Cantidad
										</th>
										<th>
											Acciones
										</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(row, index) in form.promotions" :key="index">
										<td>
											<el-select size="mini" v-model="row.item_promo_id" filterable>
												<el-option
													v-for="option in items"
													:key="option.id"
													:value="option.id"
													:label="option.name"
												></el-option>
											</el-select>
										</td>
										<td>
											<el-input-number
												size="mini"
												v-model="row.quantity"
												controls-position="right"
												:min="1"
												:step="1"
											></el-input-number>
										</td>
										<td class="text-right">
											<el-button
												size="mini"
												type="danger"
												icon="el-icon-delete"
												@click="removeItemPromo(index)"
											>
											</el-button>
										</td>
									</tr>
									<tr>
										<td class="text-right">
											<el-button
												size="mini"
												type="info"
												icon="el-icon-plus"
												@click="addItemPromo()"
											>
											</el-button>
										</td>
										<td></td>
										<td></td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-col>
					<el-divider></el-divider>
				</v-row>
				<v-row justify="center">
					<v-col cols="12" xl="12" lg="12" md="12" sm="12">
						<el-button type="danger" @click.prevent="close()">Cancelar</el-button>
						<el-button type="primary" native-type="submit" :loading="loading_submit">
							<template v-if="loading_submit">
								Guardando...
							</template>
							<template v-else>
								Guardar
							</template>
						</el-button>
					</v-col>
				</v-row>
			</form>

			<category-form
				:showDialog.sync="showDialogNewCategory"
				:external="true"
			></category-form>
		</el-dialog>
	</div>
</template>

<script>
import CategoryForm from 'Views/Admin/Categories/Form.vue';
import { deletable } from 'Mixins/deletable';
import { confirm } from 'Mixins/confirm';
import dayjs from 'dayjs';

export default {
	props: ['showDialog', 'recordId', 'external'],
	components: { CategoryForm },
	mixins: [deletable, confirm],
	data() {
		return {
			loading: false,
			loading_submit: false,
			showDialogNewCategory: false,
			titleDialog: null,
			resource: 'items',
			errors: {},
			form: {},
			categories: [],
			commandZones: [],
			unitTypes: [],
			currencyTypes: [],
			systemIscTypes: [],
			affectationIgvTypes: [],
			establishment_id: {},
			ingredients: [],
			warehouses: [],
			items: [],
			disabledChecks: false,
			pickerOptions: {
				disabledDate: (time) => {
					time = dayjs(time).format('YYYY-MM-DD');
					return dayjs().format('YYYY-MM-DD') > time;
				},
			},
		};
	},
	created() {
		this.$eventHub.$on('reloadDataCategories', (category_id) => {
			this.reloadDataCategories(category_id);
		});
	},
	methods: {
		initForm() {
			(this.loading_submit = false), (this.errors = {});
			this.form = {
				id: null,
				category_id: null,
				command_zone_id: null,
				transformed: true,
				name: null,
				description: null,
				item_type_id: '01',
				internal_id: null,
				item_code: null,
				unit_type_id: 'NIU',
				currency_type_id: 'PEN',
				has_igv: true,
				has_isc: false,
				system_isc_type_id: null,
				percentage_isc: 0,
				suggested_price: 0,
				delivery: false,
				delivery_price: 0,
				sale_affectation_igv_type_id: null,
				percentage_perception: null,
				favorite: false,
				promotion: false,
				date_of_due_promo: null,
				stock_control: false,
				has_recipe: false,
				warehouse_id: null,
				stock: 0,
				presentation: {
					id: null,
					establishment_id: null,
					name: null,
					sale_price: 0,
				},
				ingredients: [],
				promotions: [],
			};
		},
		getStaticTables() {
			this.$http.get(`/${this.resource}/static-tables`).then((response) => {
				this.currencyTypes = response.data.currencyTypes;
				this.systemIscTypes = response.data.systemIscTypes;
				this.affectationIgvTypes = response.data.affectationIgvTypes;

				this.form.sale_affectation_igv_type_id =
					this.affectationIgvTypes.length > 0 ? this.affectationIgvTypes[0].id : null;
			});
		},
		getDynamicTables() {
			this.$http.get(`/${this.resource}/dynamic-tables`).then((response) => {
				this.categories = response.data.categories;
				this.commandZones = response.data.commandZones;
				this.unitTypes = response.data.unitTypes;
				this.ingredients = response.data.ingredients;
				this.warehouses = response.data.warehouses;
				this.items = response.data.items;
				this.form.presentation.establishment_id = response.data.establishment_id;

				this.form.warehouse_id = this.warehouses.length > 0 ? this.warehouses[0].id : null;

				if (this.recordId) {
					this.$http.get(`/${this.resource}/record/${this.recordId}`).then((response) => {
						this.form = response.data.data;
					});
				}

				this.loading = true;
			});
		},
		changePromo() {
			if (this.form.promotion) {
				this.disabledChecks = true;
				this.form.ingredients = [];
				this.form.has_recipe = false;
				this.form.stock_control = false;
			} else {
				this.disabledChecks = false;
				this.form.promotions = [];
			}
		},
		changeTranformed(transformed) {
			this.form.transformed = transformed;
		},
		async create() {
			this.titleDialog = this.recordId ? 'Editar producto' : 'Nuevo producto';
			await this.initForm();
			await this.getStaticTables();
			await this.getDynamicTables();
		},
		addIngredient() {
			this.form.ingredients.push({
				id: null,
				ingredient_id: null,
				quantity: 1,
				unit_type_id: 'NIU',
			});
		},
		removeIngredient(index) {
			this.form.ingredients.splice(index, 1);
		},
		addItemPromo() {
			this.form.promotions.push({
				id: null,
				item_promo_id: null,
				quantity: 1,
			});
		},
		removeItemPromo(index) {
			this.form.promotions.splice(index, 1);
		},
		resetForm() {
			this.initForm();
			this.form.sale_affectation_igv_type_id =
				this.affectationIgvTypes.length > 0 ? this.affectationIgvTypes[0].id : null;
		},
		async submit() {
			if (this.form.transformed) {
				this.stock_control = false;
			}

			if (!this.form.transformed) {
				this.has_recipe = false;
			}

			let exist = this.form.ingredients.find((item) => item.ingredient_id == null);

			if (exist) {
				return this.$message.error(`Error! Revise la receta`);
			}

			if (this.form.has_recipe && this.form.ingredients.length == 0) {
				return this.$message.error(`Error! Agrega uno o más insumos a la receta`);
			}
			this.confirm().then(() => {
				this.loading_submit = true;
				this.$http
					.post(`/${this.resource}`, this.form)
					.then((response) => {
						if (response.data.success) {
							this.$message.success(response.data.message);
							if (this.external) {
								this.$eventHub.$emit('reloadDataItems', response.data.id);
							} else {
								this.$eventHub.$emit('reloadData', this.resource);
							}
							this.close();
						} else {
							this.$message.error(response.data.message);
						}
					})
					.catch((error) => {
						if (error.response.status === 422) {
							this.errors = error.response.data.errors;
						} else {
							this.$message.error(error.response.data.message);
						}
					})
					.then(() => {
						this.loading_submit = false;
					});
			});
		},
		close() {
			this.$emit('update:showDialog', false);
			this.resetForm();
		},
		reloadDataCategories(category_id) {
			this.$http.get(`/${this.resource}/table/categories`).then((response) => {
				this.categories = response.data;
				this.form.category_id = category_id;
			});
		},
	},
};
</script>
